import $ from "jquery";

$(() => {
  $(window).scroll(function() {
    var scrolltop = $(window).scrollTop();
    if(scrolltop > 500) {
      $('.footerpagetop').addClass('active');
    } else {
      $('.footerpagetop').removeClass('active');
    }
  });



  $('.cast-btn').click(function(e) {
    $('.cast-btn').each(function() {
      $(this).find('audio')[0].pause();
    });
    var audio = $(this).find('audio')[0];
    if(!isNaN(audio.duration)) { audio.currentTime = 0; }
    audio.play();
    return false;
  });

  // MNG都合処理
  $(document).ready(function() {
    // ヘッダ処理
    var gHeader = $("#gheader");
    if(gHeader.length > 0) {
      $.get('/_include/header.html')
      .done(function(e){
        gHeader.html(e);

        // スマホ版のメニュータップ時のイベントをここに移動
        $('.menu__toggle, .gnavi__under').click(function() {
          $('.gnavi, .gnavi__under').toggleClass('open');
        });
      })
    }

    // フッタ処理
    var gFooter = $("#gfooter");
    if(gFooter.length > 0) {
      $.get('/_include/footer.html')
      .done(function(e){
        gFooter.html(e);
      })
    }

    // ナビゲーション処理
    var sNav = $("#sNav");
    if(sNav.length > 0) {
      $.get('/_include/special_nav.html')
      .done(function(e){
        sNav.html(e);
      })
    }
  });
})

